import type {
  Bubble,
  Message,
  Room,
  RoomDetails,
  MessageApi,
  MessageEvent,
} from './types';

export function groupMessages(messages: Bubble[]) {
  return messages.reduce<Bubble[][]>((groups, message) => {
    if (
      groups.length === 0 ||
      groups[groups.length - 1][0].user.id !== message.user.id ||
      groups[groups.length - 1][0].date !== message.date
    ) {
      groups.push([message]);
    } else {
      groups[groups.length - 1].push(message);
    }
    return groups;
  }, []);
}

export function getformatMessage(room: Room, currentUserId: number): Message {
  return {
    id: room.id,
    users: room.users.map((user) => ({
      id: user.id,
      first_name: user.name,
      last_name: user.last_name,
    })),
    date: getFormatMessageDate(room.latest_message.created_at),
    message: room.latest_message.content || '',
    hash: room.hash,
    isSeen: !room.unread_messages_count,
    isSent: room.latest_message.user.id === currentUserId,
  };
}

export function getFormatMessageDate(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();

  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const time = date.toLocaleTimeString('pl-PL', {
    hour: '2-digit',
    minute: '2-digit',
  });

  if (isToday) {
    return `Dziś, ${time}`;
  } else {
    return (
      date.toLocaleDateString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
      }) + `, ${time}`
    );
  }
}

export function getFormatBubble(roomDetails: RoomDetails): Bubble[] {
  return roomDetails.messages.data.map((message) => ({
    id: message.id,
    message: message.content,
    date: getFormatMessageDate(message.created_at),
    user: {
      id: message.user.id,
      first_name: message.user.name,
      last_name: message.user.last_name,
    },
    attachment: message.attachments[0] || null,
  }));
}

export function updatePagesWithNewMessage(
  oldData: {
    pages: RoomDetails[];
  },
  message: MessageApi,
  unread_messages_count: number,
): {
  pages: RoomDetails[];
} {
  if (!oldData) return oldData;

  if (
    oldData.pages
      .flat()
      .find((page) => page.messages.data.find((msg) => msg.id === message.id))
  ) {
    return oldData;
  }

  const newPages = [...oldData.pages];

  const newMessage = {
    id: message.id,
    created_at: message.created_at,
    content: message.content,
    user: {
      id: message.user.id,
      name: message.user.name,
      last_name: message.user.last_name,
      role: message.user.role,
    },
    attachments: message.attachments,
  };

  newPages[0] = {
    ...newPages[0],
    unread_messages_count,
    messages: {
      ...newPages[0].messages,
      data: [newMessage, ...newPages[0].messages.data],
    },
  };

  for (let i = 0; i < newPages.length; i++) {
    const currentPage = { ...newPages[i] };

    if (currentPage.messages.data.length > 15) {
      const lastMessage = currentPage.messages.data.pop();

      if (lastMessage && newPages[i + 1]) {
        newPages[i + 1] = {
          ...newPages[i + 1],
          unread_messages_count: 1,
          messages: {
            ...newPages[i + 1].messages,
            data: [lastMessage, ...newPages[i + 1].messages.data],
          },
        };
      }
    }

    newPages[i] = currentPage;
  }

  return {
    ...oldData,
    pages: newPages,
  };
}

export function updateRoomsWithNewRoom(
  oldData: { pages: { rooms: Room[] }[] },
  newRoomData: {
    hash: string;
    id: number;
  },
  user: {
    full_name: string;
    formatedRoles?: string;
    id: number;
    name: string;
    last_name: string | null;
  },
  content: string,
  users: {
    full_name: string;
    formatedRoles?: string;
    id: number;
    name: string;
    last_name: string | null;
  }[],
): { pages: { rooms: Room[] }[] } {
  if (!oldData || !oldData.pages.length) return oldData;

  const newPages = [
    ...oldData.pages.map((page) => ({ ...page, rooms: [...page.rooms] })),
  ];

  const newRoom: Room = {
    ...newRoomData,
    users: users.map((user) => ({
      id: user.id,
      name: user.name,
      last_name: user.last_name,
      role: user.formatedRoles || '',
    })),
    unread_messages_count: 0,
    latest_message: {
      content: content,
      created_at: new Date().toISOString(),
      user: {
        id: user.id,
        name: user.name,
        last_name: user.last_name,
      },
    },
  };

  newPages[0].rooms = [
    newRoom,
    ...newPages[0].rooms.filter((room) => room.id !== newRoom.id),
  ];

  for (let i = 0; i < newPages.length; i++) {
    if (newPages[i].rooms.length > 15) {
      const lastRoom = newPages[i].rooms.pop();

      if (lastRoom) {
        if (newPages[i + 1]) {
          newPages[i + 1].rooms = [lastRoom, ...newPages[i + 1].rooms];
        } else {
          newPages.push({ rooms: [lastRoom] });
        }
      }
    }
  }

  return {
    ...oldData,
    pages: newPages,
  };
}

export function updateMessagesUnreadStatus(
  oldData: { pages: RoomDetails[] },
  unreadStatus: 0 | 1,
): { pages: RoomDetails[] } {
  if (!oldData) return oldData;

  return {
    ...oldData,
    pages: oldData.pages.map((page) => ({
      ...page,
      unread_messages_count: unreadStatus,
    })),
  };
}

export function updateRoomUnreadStatus(
  oldData: { pages: { rooms: Room[] }[] },
  hash: string,
  unreadStatus: 0 | 1,
): { pages: { rooms: Room[] }[] } {
  if (!oldData || !oldData.pages.length) return oldData;

  const newPages = oldData.pages.map((page) => ({
    ...page,
    rooms: page.rooms.map((room) =>
      room.hash === hash
        ? { ...room, unread_messages_count: unreadStatus }
        : room,
    ),
  }));

  return { ...oldData, pages: newPages };
}

export function getFormatedChatUsers(
  users: {
    full_name: string;
    formatedRoles?: string;
    id: number;
    name: string;
    last_name: string | null;
  }[],
) {
  return users.map((user) => {
    return {
      full_name: user.full_name,
      role: user.formatedRoles || null,
      id: user.id,
      name: user.name,
      last_name: user.last_name,
    };
  });
}

export function getFormattedEventMessage(
  message: MessageEvent,
  user: {
    id: number;
    name: string;
    last_name: string | null;
    role: string;
  },
): MessageApi {
  return {
    id: message.message_id,
    created_at: new Date().toISOString(),
    content: message.content,
    user,
    attachments: message.attachments,
  };
}

export const updateRoomOrder = (
  oldData: { pages: { rooms: Room[]; pagination: unknown }[] },
  message: MessageApi,
  hash: string,
): { pages: { rooms: Room[]; pagination: unknown }[] } => {
  if (!oldData || oldData.pages.length === 0) return oldData;

  const roomLimit = oldData.pages[0].rooms.length;

  const allRooms = oldData.pages.flatMap((page) => page.rooms);

  const foundRoom = allRooms.find((room) => room.hash === hash);
  const filteredRooms = allRooms.filter((room) => room.hash !== hash);

  if (!foundRoom) return oldData;

  const user = foundRoom.users.find((u) => u.id === message.user.id);

  const updatedRoom: Room = {
    ...foundRoom,
    unread_messages_count: foundRoom.unread_messages_count ?? 0,
    latest_message: {
      ...foundRoom.latest_message,
      content: message.content,
      created_at: new Date().toISOString(),
      user: user || foundRoom.latest_message.user,
    },
  };

  const newRooms = [updatedRoom, ...filteredRooms];

  const chunkedPages = Array.from(
    { length: Math.ceil(newRooms.length / roomLimit) },
    (_, i) => ({
      rooms: newRooms.slice(i * roomLimit, (i + 1) * roomLimit),
      pagination: oldData.pages[i]?.pagination,
    }),
  );

  return { ...oldData, pages: chunkedPages };
};

export const isRoomOnList = (
  oldData: { pages: { rooms: Room[]; pagination: unknown }[] },
  hash: string,
): boolean => {
  if (!oldData || oldData.pages.length === 0) return false;

  const allRooms = oldData.pages.flatMap((page) => page.rooms);

  const foundRoom = allRooms.find((room) => room.hash === hash);

  if (!foundRoom) return false;

  return true;
};

export const unreadRoom = (
  oldData: { pages: { rooms: Room[]; pagination: unknown }[] },
  hash: string,
): { pages: { rooms: Room[]; pagination: unknown }[] } => {
  if (!oldData || oldData.pages.length === 0) return oldData;

  return {
    ...oldData,
    pages: oldData.pages.map((page) => ({
      ...page,
      rooms: page.rooms.map((room) =>
        room.hash === hash ? { ...room, unread_messages_count: 1 } : room,
      ),
    })),
  };
};

export const updateLeaveRooom = (
  oldData: { pages: { rooms: Room[]; pagination: unknown }[] },
  hash: string,
): { pages: { rooms: Room[]; pagination: unknown }[] } => {
  if (!oldData || oldData.pages.length === 0) return oldData;

  const roomLimit = oldData.pages[0].rooms.length;

  const allRooms = oldData.pages.flatMap((page) => page.rooms);
  const filteredRooms = [...allRooms.filter((room) => room.hash !== hash)];

  const chunkedPages = Array.from(
    { length: Math.ceil(filteredRooms.length / roomLimit) },
    (_, i) => ({
      rooms: filteredRooms.slice(i * roomLimit, (i + 1) * roomLimit),
      pagination: oldData.pages[i]?.pagination,
    }),
  );

  return { ...oldData, pages: chunkedPages };
};

export function updateRoomsUsers(
  oldData: { pages: { rooms: Room[] }[] },
  hash: string,
  users: {
    full_name: string;
    formatedRoles?: string;
    id: number;
    name: string;
    last_name: string | null;
  }[],
): { pages: { rooms: Room[] }[] } {
  if (!oldData || !oldData.pages.length) return oldData;

  if (!oldData || oldData.pages.length === 0) return oldData;

  const newPages = oldData.pages.map((page) => ({
    ...page,
    rooms: page.rooms.map((room) =>
      room.hash === hash
        ? {
            ...room,
            users: [
              ...room.users,
              ...users.map((user) => ({
                id: user.id,
                name: user.name,
                last_name: user.last_name,
                role: user.formatedRoles || '',
              })),
            ],
          }
        : room,
    ),
  }));

  return { ...oldData, pages: newPages };
}

export function updateMessageUsers(
  oldData: { pages: RoomDetails[] },
  users: {
    full_name: string;
    formatedRoles?: string;
    id: number;
    name: string;
    last_name: string | null;
  }[],
): { pages: RoomDetails[] } {
  if (!oldData) return oldData;

  return {
    ...oldData,
    pages: oldData.pages.map((page) => ({
      ...page,
      users: [
        ...page.users,
        ...users.map((user) => ({
          id: user.id,
          name: user.name,
          last_name: user.last_name,
          role: user.formatedRoles || '',
        })),
      ],
    })),
  };
}
