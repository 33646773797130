import { z } from 'zod';

export const createRoomFormSchema = z.object({
  content: z
    .string({
      message: 'Pole nie może być puste',
    })
    .min(1, {
      message: 'Pole nie może być puste',
    }),
  user_id: z.number({
    message: 'Wybierz użytkownika',
  }),
  user_ids: z.array(z.number()),
});

export const roomSchema = z.object({
  id: z.number(),
  latest_message: z.object({
    content: z.string().nullable(),
    created_at: z.string(),
    user: z.object({
      id: z.number(),
      name: z.string(),
      last_name: z.string().nullable(),
    }),
  }),
  users: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      last_name: z.string().nullable(),
      role: z.string(),
    }),
  ),
  unread_messages_count: z.number().nullable(),
  hash: z.string(),
});

export const roomsSchema = z.object({
  rooms: z.array(roomSchema),
  pagination: z.object({
    total: z.number(),
    current_page: z.number(),
    last_page: z.number(),
  }),
});

export const attachmentSchema = z.object({
  mime_type: z.string(),
  url: z.string(),
  url_download: z.string(),
  original_name: z.string(),
});

export const messageSchema = z.object({
  id: z.number(),
  created_at: z.string(),
  content: z.string().nullable(),
  user: z.object({
    id: z.number(),
    name: z.string(),
    last_name: z.string().nullable(),
    role: z.string(),
  }),
  attachments: z.array(attachmentSchema),
});

export const roomDetailsSchema = z.object({
  id: z.number(),
  unread_messages_count: z.number().nullable(),
  users: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      last_name: z.string().nullable(),
      role: z.string(),
    }),
  ),
  hash: z.string(),
  messages: z.object({
    data: z.array(messageSchema),
    meta: z.object({
      total: z.number(),
      current_page: z.number(),
      last_page: z.number(),
    }),
  }),
});

export const inviteUsersFormSchema = z.object({
  user_ids: z
    .array(z.number(), {
      message: 'Wybierz użytkowników',
    })
    .min(1, {
      message: 'Wybierz użytkowników',
    }),
});

export const messageAddedEventSchema = z.object({
  room_hash: z.string(),
  message_id: z.number(),
  event_user_id: z.number(),
  attachments: z.array(attachmentSchema),
  content: z.string().nullable(),
});

export const roomInvitedEventSchema = z.object({
  what_user_id: z.number(),
  what_user_name: z.string(),
  room_hash: z.string(),
  event_user_id: z.number(),
  what_user_last_name: z.string().nullable(),
});
