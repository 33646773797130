import { z } from 'zod';
import { fetcher } from '~/utils/fetcher';

import type { CreateRoomForm } from './types';
import { roomsSchema, roomDetailsSchema, messageSchema } from './schemas';

const CHAT_ENDPOINT = '/chat';

export const chatService = {
  getChatRooms: async (payload: { page: number; search: string }) => {
    return await fetcher(
      `${CHAT_ENDPOINT}/rooms?page=${payload.page}&search=${payload.search}`,
      {
        method: 'GET',
        schema: roomsSchema,
        meta: true,
      },
    );
  },
  createChatRoom: async (payload: CreateRoomForm) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms`, {
      method: 'POST',
      body: payload,
      schema: z.object({
        hash: z.string(),
        id: z.number(),
      }),
    });
  },
  getRoomMessages: async (payload: { page: number; hash: string }) => {
    return await fetcher(
      `${CHAT_ENDPOINT}/rooms/${payload.hash}?page=${payload.page}&per_page=15`,
      {
        method: 'GET',
        schema: roomDetailsSchema,
      },
    );
  },
  sendMessage: async (payload: {
    hash: string;
    content: string;
    attachment_tokens: string[];
  }) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms/${payload.hash}/messages`, {
      method: 'POST',
      body: {
        content: payload.content,
        attachment_tokens: payload.attachment_tokens,
      },
      schema: messageSchema,
    });
  },
  readMessagesInRoom: async (hash: string) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms/${hash}/set-read`, {
      method: 'POST',
    });
  },
  inviteUsersToRoom: async (payload: { hash: string; user_ids: number[] }) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms/${payload.hash}/invite`, {
      method: 'POST',
      body: { user_ids: payload.user_ids },
    });
  },
  unreadRoom: async (hash: string) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms/${hash}/set-unread`, {
      method: 'POST',
    });
  },
  leaveRoom: async (hash: string) => {
    return await fetcher(`${CHAT_ENDPOINT}/rooms/${hash}/leave`, {
      method: 'POST',
    });
  },
  getChatStatus: async () => {
    return await fetcher(`${CHAT_ENDPOINT}/status`, {
      method: 'GET',
      schema: z.object({
        room_unread_count: z.number(),
      }),
      meta: true,
    });
  },
};
